a.anchor-link {
    font-size: 1.125em;
    margin-right: 4vw;
    border: none;
    text-decoration: none;
}

a.anchor-link:hover {
    color: #0635C9;
    border-bottom: 2px solid #0635C9;
}


a.anchor-link.primary {
    text-decoration: none;
    padding: 11px 44px 10px 40px;
    background: #0635C9;
    border-radius: 2px;
    font-weight: 600;
    font-size: 1em;
    color: #FFFFFF;
    border: 2px solid #0635C9;
}

a.anchor-link.primary:hover {
    background-color: #FFFFFF;
    color: #0635C9;
}


@media screen and (max-device-width: 767px) {
    .anchor-link {
        display: none;
    }

    a.anchor-link.primary {
        display: flex;
    }
}
