@import "./Styles/Typography.css";
@import "./Styles/Icons.css";

body {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

li {
    display: flex;
    margin-bottom: 1em;
}

li:before {
    content: "*";
    font-family: 'Chakra Petch', sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #0635C9;
    font-size: 1.5em;
    margin: 0.25em 0.25em 0 0;
}

.content-container {
    padding: 0 4% 0 4%;
}

.padded-content {
    padding: 0 5%;
}
