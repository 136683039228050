.contact-me {
    display: flex;
    flex-direction: column;
}

.contact-me.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.contact-me .field {
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    display: flex;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    padding: 0 0 0 32px;
}

.contact-me .field > textarea {
    resize: none;
}

.contact-me .field > label {
    padding: 0 0 8px 0;
    text-transform: uppercase;
    color: #FFF;
    font-size: 0.75em;
}

.contact-me .field > input.body-text, textarea.body-text {
    align-items: flex-start;
    padding: 12px 14px 13px;
    color: #B4C2EF;
    background: #0635C9;
    border: 2px solid rgba(180, 194, 239, 0.7);
    border-radius: 2px;
    font-weight: 300;
}

.contact-me .field > input.body-text::-webkit-input-placeholder, textarea.body-text::-webkit-input-placeholder {
    color: #B4C2EF;
}

.contact-me .field > input.body-text::-moz-placeholder, textarea.body-text::-moz-placeholder {
    color: #B4C2EF;
}

.contact-me .field > input.body-text::-ms-input-placeholder, textarea.body-text::-ms-input-placeholder {
    color: #B4C2EF;
}

.contact-me .field > input.body-text::placeholder, textarea.body-text::placeholder {
    color: #B4C2EF;
}

.contact-me .field > input:focus, textarea:focus {
    border-color: #FFF;
    color: #FFF;
    outline: none;
}

.contact-me .row > .field {
    width: 100%;
}

.contact-me .col {
    width: 50%;
    justify-content: start;
}

.contact-me .row  {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    width: 100%;
}

.footer-container > .content .left .anchor-button {
    background-color: #FFFFFF;
    color: #0635C9;
    max-width: 100px;
    margin-top: 5vh;
    display: inline-block;
    border: 2px solid #FFFFFF;
}

.contact-me button.submit {
    text-decoration: none;
    font-weight: 600;
    font-size: 1em;

    padding: 11px 44px 10px 40px;
    border-radius: 2px;
    background-color: #FFFFFF;
    color: #0635C9;
    max-width: 190px;
    margin-top: 40px;
    display: inline-block;
    border: 2px solid #FFFFFF;
    margin-left: 32px;
    cursor: pointer;
}

.contact-me button.submit:hover {
    background-color: #0635C9;
    color: #FFFFFF;
}

@media screen and (max-device-width: 1000px) {
    .contact-me .field {
        padding: 0;
        width: 80vw;
    }

    .contact-me button.submit {
        margin-left: 0;
        margin-top: 36px;
    }

    .contact-me .row {
        flex-direction: column;
    }

    .contact-me .col {
        width: 100%;
    }
}
