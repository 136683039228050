.footer-container {
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: #0635C9;
    padding: 5vh 10vw 3vh 10vw;
}

.footer-container .content {
    width: 100%;
    flex-direction: row;
    display: flex;
}

.footer-container .content .left {
    width: 35%;
}

.footer-container .content .right {
    width: 50%;
    padding: 3% 0 0 15%;
}

.footer-container .col {
    display: flex;
    justify-content: space-around;
    width: 50%;
    margin-bottom: 40px;
}

.footer-container > .divider {
    width: 100%;
    align-self: center;
    margin-top: 7vh;
    border-top: 1px solid #9AB2F9;
}

.footer-container > span.body-text {
    margin-top: 3vh;
    font-size: 1em;
    color: #9AB2F9;
}

.footer-container > .content .left h2.title {
    color: #FFFFFF;
    font-size: 3em;
    margin-bottom: 5%;
}

.footer-container span.icon.icon-google.medium.light {
    cursor: default;
    text-decoration: none;
    pointer-events: none;
    width: 80px;
    height: 80px;
    opacity: 1;
}

.footer-container .content .links span.icon {
    background-color: #9AB2F9;
    opacity: 1;
    margin-top: 10vh;
    margin-right: 10vw;
}

@media screen and (max-device-width: 1000px) {
    .footer-container > span.body-text {
        font-size: 0.825em;
        margin-top: 18px;
    }

    .footer-container .content {
        flex-direction: column;
    }

    .footer-container .content .left {
        width: 100%;
        padding-bottom: 3vh;
    }

    .footer-container .content .right {
        width: 100%;
        padding: 0;
    }

    .footer-container .col {
        margin-bottom: 28px;
    }

    .footer-container > .divider {
        margin-top: 5vh;
    }
}
