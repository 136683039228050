@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500;700&display=swap');


.button-text {
    font-family: 'Chakra Petch', sans-serif;
    font-style: normal;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #0D1C2E;
    font-weight: 500;
}

b {
    color: #0635C9;
}

.title {
    font-family: 'Chakra Petch', sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #0D1C2E;
    line-height: 130%;
}

h1.title {
    font-size: 3.5em;
}

h2.title {
    font-size: 2.5em;
}

h3.title {
    font-size: 1.5em;
    font-weight: 600;
}

.body-text {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 200;
    line-height: 160%;
    color: #0D1C2E;
    font-size: 1.125em;
}

.body-text.consent-block {
    color: #FFFFFF;
    font-size: 1.225em;
}

.body-text.secondary {
    line-height: 130%;
    font-size: 0.875em;
}

.body-text > b {
    font-weight: 400;
}

.body-text b.primary {
    font-weight: 900;
    text-transform: uppercase;
}

.footer-text {
    font-weight: 300;
    color: #FFFFFF;
}

.body-text.footer-text {
    font-size: 1.25em;
}

.transparent-1 {
    opacity: 0.9;
}

.transparent-2 {
    opacity: 0.7;
}

@media screen and (max-device-width: 767px) {
    h1.title {
        font-size: 2.5em;
    }

    h2.title {
        font-size: 2em;
    }

    h3.title {
        font-size: 1.25em;
    }

    .body-text {
        font-size: 1em;
    }
}
