.hero-container {
    width: 100%;
    height: 100vh;
}

.hero > p.body-text {
    line-height: 150%;
    font-size: 1.5em;
}

.hero .body-text > b {
    font-weight: 500;
}

.header {
    height: 15%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.header-logo {
    flex: auto;
    justify-self: flex-start;
    width: 44px;
    height: 44px;
}

.hero-container .content {
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 15% 0 10%;
}

.hero-footer {
    padding: 2% 0 0 10%;
}

.hero-footer.links > a {
    margin-right: 4vw;
}

.hero-container .content .title {
    margin-top: 7%;
    margin-bottom: 0;
}


@media screen and (max-device-width: 767px) {
    .hero > p.body-text {
        font-size: 1.125em;
    }

    .hero-footer.links > a {
        margin-right: 10vw;
    }
}
