.summary-container {
    height: auto;
    width: 100%;
    padding: min(180px, 20%) 0 min(180px, 20%) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.summary-container > .body-text {
    text-align: center;
    width: 70%;
    max-width: 900px;
    min-width: 327px;
    margin-top: 0;
}
