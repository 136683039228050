.services-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: min(180px, 20%);
}

.services-container .do-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    margin-top: 56px;
}

.services-container h3.title {
    margin-top: 32px;
    margin-bottom: 16px;
}

.services-container .col {
    width: 50%;
}

.services-container .col.right {
    margin-top: -1.8em;
}

@media screen and (max-device-width: 767px) {
    .services-container .col {
        width: 100%;
    }
}

